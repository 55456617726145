import React, { useState } from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import About from '../components/views/About/About'
import Contact from '../components/views/Contact/Contact'
import Footer from '../components/Layout/Footer/Footer'

export default function ContactTemplate({
  pageContext: { indicators, areas, placeNames },
}) {
  const [page, setPage] = useState(null)
  return (
    <Layout page={page}>
      <SEO title="Contact Us" description="Contact the HIV Policy Lab team" />
      <About
        {...{
          setPage,
          firstView: 'contact',
          indicators,
          areas,
          placeNames,
        }}
      />
      {/* <Footer /> */}
    </Layout>
  )
}
